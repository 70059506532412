import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg';

const Footer = (props) => (
    <footer id="footer">
        <p className="copyright">&copy; Digital Rainclouds. Design: <a href="https://html5up.net">HTML5 UP</a> && <a href="http://jonprell.com">Jon Prell</a>.</p>
    </footer>
)

export default Footer
